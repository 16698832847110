import React from 'react'

type IIconProps = {
  color?: string
}

const SearchIconNew: React.FC<React.SVGProps<SVGSVGElement> & IIconProps> = ({
  color = 'currentColor',
  ...rest
}) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...rest}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-2.000000, -2.000000)" fill={color}>
          <path d="M15.493984,14.0963928 C16.45784,12.8265125 17.06025,11.2325357 17.06025,9.50120932 C17.06025,5.35783334 13.686754,2 9.590366,2 C5.373496,2 2,5.35783334 2,9.50120932 C2,13.6433805 5.373496,17.0012138 9.590366,17.0012138 C11.277114,17.0012138 12.84338,16.4144665 14.168682,15.4289237 M15.1557704,16.5715819 C16.3018801,17.7131072 18.0210446,19.4253951 20.313264,21.7084456 C20.67471,22.0988072 21.397602,22.0988072 21.759048,21.7084456 C22.120494,21.3180839 22.120494,20.6843486 21.759048,20.2939869 C19.4554231,17.9995765 17.7277045,16.2787687 16.575892,15.1315636 M4.048194,9.50120932 C4.048194,6.46265328 6.457834,4.0000012 9.590366,4.0000012 C12.602416,4.0000012 15.012056,6.46265328 15.012056,9.50120932 C15.012056,12.5385605 12.602416,15.0012126 9.590366,15.0012126 C6.457834,15.0012126 4.048194,12.5385605 4.048194,9.50120932 Z"></path>
        </g>
      </g>
    </svg>
  )
}

export default SearchIconNew
